import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/index/index";
import BlankGrid from "./pages/blank/index";
import CharGen from "./pages/char-gen";
import ParaGen from "./pages/para-gen";
import WordSheet from "./pages/word-sheet";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
function App() {
  return (
    <ChakraProvider resetCSS={true}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/char-gen" element={<CharGen />}></Route>
          <Route path="/para-gen" element={<ParaGen />}></Route>
          <Route path="/word-sheet" element={<WordSheet />}></Route>
          <Route path="/blank" element={<BlankGrid />}></Route>
          <Route path="/blank-py" element={<BlankGrid withPy={true} />}></Route>
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
