import {
  Button,
  ButtonGroup,
  Textarea,
  FormLabel,
  FormControl,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./index.scss";

const CELL_PER_LINE = 12;

export default function WordSheet() {
  const [chars, setChars] = useState("");
  const [blankLine, setBlankLine] = useState(1);
  useEffect(() => {
    window.onbeforeprint = () => {
      document.getElementById("panel").classList.add("print");
    };
    window.onafterprint = () => {
      document.getElementById("panel").classList.remove("print");
    };
    return () => {
      window.onbeforeprint = null;
      window.onafterprint = null;
    };
  }, []);
  const regulatedChars = chars.replaceAll(/[^A-Za-z]+/g, " ");
  console.log(blankLine);
  return (
    <>
      <Helmet>
        <title>英语字帖</title>
      </Helmet>
      <div id="panel" className={`panel`}>
        <FormControl my={2}>
          <FormLabel htmlFor="email-alerts" mb="2">
            输入需要生成的<b>英文单词</b>:
          </FormLabel>
          <Textarea
            height={250}
            value={chars}
            className="input"
            placeholder="使用任意非字母分隔"
            onChange={(e) => {
              setChars(e.target.value);
            }}
            name="chars"
          ></Textarea>
        </FormControl>
        <FormControl
          my={2}
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <FormLabel htmlFor="email-alerts" mb="0">
            空白行数
          </FormLabel>
          <NumberInput
            width={100}
            size="sm"
            defaultValue={0}
            value={blankLine}
            min={1}
            max={5}
            step={1}
            onChange={setBlankLine}
          >
            <NumberInputField textAlign={"right"} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        <FormControl my={2} pt={4} display="flex" justifyContent={"flex-end"}>
          <Button
            // isDisabled={regulatedChars.length === 0}
            width={"100%"}
            colorScheme="blue"
            className="submit"
            onClick={() => {
              window.print();
            }}
          >
            打印
          </Button>
        </FormControl>
      </div>
      <div id="container" className={"word-sheet"}>
        {regulatedChars
          .split(" ")
          .filter((i) => i != "")
          .map((i) => (
            <WordLine key={i} word={i} lines={blankLine}></WordLine>
          ))}
      </div>
    </>
  );
}
const WordLine = React.memo(({ word, lines }) => {
  return (
    <div className="word-line">
      <div className="word">{word}</div>
      {new Array(+lines).fill(0).map((_, index) => (
        <div className="line-wrapper" key={word + index}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      ))}
    </div>
  );
});
