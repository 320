import {
  Button,
  ButtonGroup,
  Textarea,
  Switch,
  FormLabel,
  FormControl,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import useAsync from "../../hooks/useAsync.ts";
import { Helmet } from "react-helmet";
import { pinyin } from "pinyin-pro";
import "./index.css";

const CELL_PER_LINE = 12;

export default function CharGen() {
  const [chars, setChars] = useState("");
  const [showPinYin, setShowPinyin] = useState(true);
  const [showStep, setShowStep] = useState(false);
  const [sketchLine, setSketchLine] = useState(1);
  const [blankLine, setBlankLine] = useState(0);
  useEffect(() => {
    window.onbeforeprint = () => {
      document.getElementById("panel").classList.add("print");
    };
    window.onafterprint = () => {
      document.getElementById("panel").classList.remove("print");
    };
    return () => {
      window.onbeforeprint = null;
      window.onafterprint = null;
    };
  }, []);
  const regulatedChars = chars.replaceAll(/[^一-龟]/g, "");
  return (
    <>
      <Helmet>
        <title>田字格生成</title>
      </Helmet>
      <div id="panel" className={`panel`}>
        <FormControl my={2}>
          <FormLabel htmlFor="email-alerts" mb="2">
            输入需要生成的<b>汉字</b>:
          </FormLabel>
          <Textarea
            height={250}
            value={chars}
            className="input"
            placeholder="无需换行或分隔符"
            onChange={(e) => {
              setChars(e.target.value);
            }}
            name="chars"
          ></Textarea>
        </FormControl>
        {/* <FormControl
          my={2}
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <FormLabel htmlFor="email-alerts" mb="0">
            笔顺
          </FormLabel>
          <Switch
            isChecked={showStep}
            onChange={(e) => {
              setShowStep(e.target.checked);
            }}
            id="showStep"
          />
        </FormControl> */}
        <FormControl
          my={2}
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <FormLabel htmlFor="email-alerts" mb="0">
            拼音栏
          </FormLabel>
          <Switch
            isChecked={showPinYin}
            onChange={(e) => {
              setShowPinyin(e.target.checked);
            }}
            id="showStep"
          />
        </FormControl>

        {/* <FormControl
          my={2}
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <FormLabel htmlFor="email-alerts" mb="0">
            描写行数
          </FormLabel>
          <NumberInput
            size="sm"
            defaultValue={1}
            value={sketchLine}
            width={100}
            min={0}
            max={5}
            step={1}
            onChange={setSketchLine}
          >
            <NumberInputField textAlign={"right"} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl> */}
        <FormControl
          my={2}
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <FormLabel htmlFor="email-alerts" mb="0">
            空白行数
          </FormLabel>
          <NumberInput
            width={100}
            size="sm"
            defaultValue={0}
            value={blankLine}
            min={0}
            max={5}
            step={1}
            onChange={setBlankLine}
          >
            <NumberInputField textAlign={"right"} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        <FormControl my={2} pt={4} display="flex" justifyContent={"flex-end"}>
          <Button
            isDisabled={regulatedChars.length === 0}
            width={"100%"}
            colorScheme="blue"
            className="submit"
            onClick={() => {
              window.print();
            }}
          >
            打印
          </Button>
        </FormControl>
      </div>
      <div
        id="container"
        className={"container " + (!showPinYin && "no-pinyin")}
      >
        {[...new Set(regulatedChars.split(""))].map((char) => (
          <>
            <CharRow
              key={char}
              char={char}
              blankLine={+blankLine}
              sketchLine={+sketchLine}
              showStep={showStep}
            ></CharRow>
          </>
        ))}
      </div>
    </>
  );
}

const CharRow = React.memo(
  ({
    char = "",
    sketchLine = 1,
    blankLine = 0,
    showPinyin = true,
    showStep = true,
  }) => {
    const { value: strokes, execute: fetchStrokes } = useAsync(async () => {
      const { data } = await axios.get(
        `./strokes/${char.charCodeAt(0).toString(16)}.json`
      );
      return data.strokes;
    });
    useEffect(() => {
      fetchStrokes();
    }, [char]);
    if (!strokes) return null;
    const eles = [
      <Cell>
        <Stroke paths={strokes} fill="black" />
      </Cell>,
      ...new Array(
        Math.ceil((strokes.length + 1) / CELL_PER_LINE) * CELL_PER_LINE - 1
      )
        .fill(1)
        .map((_, index) => (
          <Cell key={`${char}_${index}`}>
            <Stroke paths={strokes?.slice(0, index + 1)} fill="#ccc" />
          </Cell>
        )),
      ...new Array(blankLine * CELL_PER_LINE)
        .fill(1)
        .map((_, index) => <Cell key={`${char}_${index}_blank`}></Cell>),
    ];
    const lines = [];
    while (eles.length != 0) {
      lines.push(eles.splice(0, CELL_PER_LINE));
    }
    return lines.map((line, index) => (
      <div className="char-wrapper" key={index}>
        {line.map((i) => i)}
      </div>
    ));
  }
);
const Stroke = ({ paths = [], fill = "black" }) => (
  <svg
    style={{
      width: "100%",
      height: "100%",
      transform: "scale(1,-1)translateY(10%)",
    }}
    viewBox="0 0 1024 1024"
  >
    {paths?.map((x) => (
      <path key={x} fill={fill} d={x}></path>
    ))}
  </svg>
);

export const Cell = ({ children = "", pinyin = "" }) => {
  return (
    <div className="cell">
      <div className="py">
        <div className="line"></div>
        <div className="line"></div>
        <div className="content">{pinyin}</div>
      </div>
      <div className="char">
        <div className="h-grid"></div>
        <div className="v-grid"></div>
        <div className="body">{children}</div>
      </div>
    </div>
  );
};
