/*
 * @Author: Junjie Yu amoksp@live.com
 * @Date: 2023-11-20 11:31:31
 * @LastEditors: Junjie Yu amoksp@live.com
 * @LastEditTime: 2023-11-20 13:08:35
 * @FilePath: \CI-test\src\pages\index\index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default function Home() {
  return <div>this is home</div>;
}
