import "../char-gen/index.css";
import { Cell } from "../char-gen/index";
export default function BlankGrid({ withPy = false }) {
  return (
    <div className={!withPy && "no-pinyin"}>
      {new Array(withPy ? 10 : 16).fill(0).map(() => (
        <div className="line">
          {new Array(11).fill(1).map(() => (
            <Cell></Cell>
          ))}
        </div>
      ))}
    </div>
  );
}
